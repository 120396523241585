import {
  LIST_UNIVERSITIES_LOADING,
  LIST_UNIVERSITIES_SUCCESS,
  LIST_UNIVERSITIES_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listUniversitiesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: LIST_UNIVERSITIES_LOADING, payload: true });
    const response = await listItemsService("api/Universities/GetAll");
    dispatch({ type: LIST_UNIVERSITIES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: LIST_UNIVERSITIES_FAILURE, payload: err });
  } finally {
    dispatch({ type: LIST_UNIVERSITIES_LOADING, payload: false });
  }
};
