import { SIGN_IN_LOADING, SIGN_IN_SUCCESS, SIGN_IN_FAILURE } from "redux/types";

const initialState = {
  signInLoading: false,
  data: null,
  token: null,
  userId: null,
  role: null,
  isCompleted: null,
  errors: null,
  firstName: null,
  lastName: null,
  userProfileImage: null,
};

export const signInReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_IN_LOADING:
      return { ...state, signInLoading: action.payload };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        token: action.payload.token,
        userId: action.payload.userId,
        role: action.payload.roles[0],
        isCompleted: action.payload.isCompleted,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userProfileImage: action.payload?.profileImage,
      };
    case SIGN_IN_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
