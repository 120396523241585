import { Link } from "react-router-dom";
import DropDown from "./DropDown";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { DefaultUserImage } from "assets/images";
import { BASE_IMAGE_URL } from "constants/constants";
import { useEffect, useState } from "react";
import { DownArrowBlack } from "assets/icons";

const NavBar = ({ formik }: { formik: any }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const { dataComplete } = useSelector(
    (state: RootState) => state.completeRegistrationReducer
  );

  // dataComplete?.profileImage

  const { firstName, userProfileImage } = useSelector(
    (state: RootState) => state.signInReducer
  );

  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    setProfileImage(
      dataComplete?.profileImage
        ? dataComplete?.profileImage
        : userProfileImage
        ? userProfileImage
        : ""
    );
  }, [profileData]);

  return (
    <div className="hidden h-full md:flex items-center gap-[32px] font-Avenir_regular text-[16px]">
      {/*  navBar */}
      <Link to={"/companies"}>Companies</Link>
      <Link to={"/matchedInternships"}>My Jobs</Link>
      <Link to={"/applications"}>My Applications</Link>

      {/* user name and info */}
      <div className="h-full flex items-center gap-[10px] relative cursor-pointer">
        <img
          className="w-[40px] h-[40px] rounded-full object-cover"
          referrerPolicy="no-referrer"
          src={
            profileImage && profileImage !== ""
              ? encodeURI(BASE_IMAGE_URL + profileImage)
              : // : !data || data?.picture === ""
                // ?
                DefaultUserImage
            // : `${encodeURI(data?.picture)}`
          }
          alt="user"
        />
        <div
          onClick={() => setShowDropDown(!showDropDown)}
          className="flex items-center gap-4"
        >
          Hi,{" "}
          {dataComplete?.firstName
            ? dataComplete?.firstName
            : profileData?.firstName
            ? profileData?.firstName
            : firstName}
          <img src={DownArrowBlack} alt="" />
        </div>

        {/* show the drop down pop  */}
        {showDropDown ? <DropDown formik={formik} /> : ""}
      </div>
    </div>
  );
};

export default NavBar;
