import {
  LIST_AREAS_LOADING,
  LIST_AREAS_SUCCESS,
  LIST_AREAS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listAreasAction = (cityId: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: LIST_AREAS_LOADING, payload: true });
    const response = await listItemsService(
      `api/Areas/GetByCityId`,
      `cityId=${cityId}`
    );
    dispatch({ type: LIST_AREAS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: LIST_AREAS_FAILURE, payload: err });
  } finally {
    dispatch({ type: LIST_AREAS_LOADING, payload: false });
  }
};
