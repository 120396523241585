import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { listAreasAction } from "redux/actions/areasActions/listAreasAction";

interface citySearchElements {
  title: string;
  showMore: boolean;
  cities: {}[];
  borderBottom: number;
  formik?: any;
  name?: string;
}

function CitySearch({
  title,
  showMore,
  cities,
  borderBottom,
  formik,
  name,
}: citySearchElements) {
  const dispatch: any = useDispatch();

  const [cityCount, setCityCount] = useState(4);

  const [searchParams, setSearchParams] = useSearchParams();

  const [boxesChecked, setBoxesChecked] = useState<boolean[]>(
    cities?.map((item: any) => false)
  );
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setBoxesChecked(cities?.map((item: any) => false));
  }, [cities]);

  return (
    <div
      style={{
        borderBottom: `${borderBottom}px solid #0000000D`,
      }}
      className="py-[15px] w-full bg-white flex flex-col gap-[10px]  text-pro_text_dark"
    >
      {/* header  */}
      <p className="font-Avenir_demi text-[20px] ">{title}</p>
      <form className="flex flex-col gap-[10px]">
        {/* checkboxs  */}
        {cities?.slice(0, cityCount).map((City: any, i: number) => {
          return (
            <div key={i}>
              <label
                className="relative flex items-center"
                htmlFor={City?.cityNameEn}
              >
                {/* checkbox input  **************************/}
                <input
                  className={`w-[20px] h-[20px] mr-[15px] appearance-none border-2 border-black rounded-[5px]`}
                  name={name}
                  id={City?.cityNameEn}
                  value={City?.cityId}
                  checked={boxesChecked?.at(i)}
                  type="checkbox"
                  onChange={() => {
                    // setBoxesChecked(cities.map((item: any) => false));
                    const newChecked = boxesChecked?.map(
                      (item: boolean, idx: number) =>
                        i === idx ? !item : false
                    );
                    setBoxesChecked(newChecked);
                    if (!boxesChecked?.at(i)) {
                      searchParams.set("cityId", City?.cityId);
                    } else {
                      searchParams.delete("cityId");
                    }
                    dispatch(listAreasAction(City?.cityId));
                    setSearchParams(searchParams);
                  }}
                />
                {City?.cityNameEn}
                <div
                  className={`w-[20px] h-[20px] bg-green-500 absolute top-[8%] left-0 ${
                    boxesChecked?.at(i) ? "opacity-100" : "opacity-0"
                  } duration-300 rounded-[15px]`}
                >
                  <img src="images/checkbox.png" alt="selected" />
                </div>
              </label>
            </div>
          );
        })}
      </form>

      {/* show more title  */}
      {showMore && (
        <p
          onClick={() => setCityCount((prev) => prev + cities.length - prev)}
          className="font-Avenir_demi text-[16px] underline cursor-pointer hover:text-[#69b1ff] duration-300"
        >
          Show More
        </p>
      )}
    </div>
  );
}

export default CitySearch;
